<template>
  <div>
    <BusinessHeader v-if="user.role === 'superadmin'"> </BusinessHeader>

    <div class="bg-white w-full py-4 px-7 border-gray-200 border-b-2">
      <button
        class="bg-gray-700 text-white py-2 px-4 flex items-center rounded-full"
        @click="toggleModal()"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="plus"
          class="svg-inline--fa fa-plus fa-w-14 inline font-light mr-3"
          width="20px"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            stroke-width="1%"
            d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
          ></path>
        </svg>
        Add Locations
      </button>
    </div>

    <div class="flex-row inline-flex w-full py-4">
      <div class="w-1/5 text-center">Name</div>
      <div class="w-1/5 text-center">Phone Number</div>
      <div class="w-1/5 text-center ">Status</div>
      <div class="w-1/5 "></div>
      <div class="w-1/5"></div>
    </div>

    <div
      class="flex-row inline-flex w-full border-t-2 border-gray-200 bg-white py-3"
      v-for="single_location in locations"
      :key="single_location.id"
    >
      <div class="w-1/5 text-center">
        <a href="#" class="text-blue-400 capitalize">
          {{ single_location.location_name }}
        </a>
      </div>
      <div class="w-1/5 text-center">{{ single_location.twilio_number }}</div>
      <div
        class="w-1/5 text-center capitalize"
        :class="
          single_location.status === 'active'
            ? 'text-green-500'
            : ' text-red-500'
        "
      >
        {{ single_location.status }}
      </div>
      <div class="w-1/5 text-center">
        <a
          href="#"
          class="text-blue-400"
          @click="editLocation(single_location)"
        >
          Edit
        </a>
      </div>
      <div class="w-1/5 text-center">
        <a
          href="#"
          :class="
            single_location.status === 'active'
              ? 'text-red-500'
              : ' text-green-500'
          "
          @click="toggleConfirmationStatus(single_location)"
        >
          {{ single_location.status === "active" ? "Deactivate" : "Activate" }}
        </a>
      </div>
    </div>

    <div
      class="flex-row inline-flex w-full border-t-2 border-gray-200 bg-white py-3"
      v-if="locations.length != 0"
    >
      <div class="w-1/5 text-center"></div>
      <div class="w-1/5 text-center"></div>
      <div class="w-1/5 text-center"></div>
      <div class="w-1/5 text-center"></div>
      <div class="w-1/5 text-center">{{ locations.length }} Locations</div>
    </div>

    <!-- modal overlay  -->
    <div
      class="h-screen w-full fixed top-0 right-0 bg-gray-700 opacity-25"
      v-if="showPopup || showEditPopup"
    ></div>

    <!-- add business popup -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div
          class="bg-white rounded shadow pb-8 m-4 max-h-full text-center w-96"
        >
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Add Location Information
          </div>
          <form @submit.prevent="addLocation" class="px-4">
            <div class="items-center flex flex-col mt-7">
              <div class="flex flex-col mb-6 w-full mt-2">
                <input
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  type="text"
                  placeholder="Name"
                  v-model="location.name"
                  name="location_name"
                  autocomplete="off"
                  required
                  id="location_name"
                />
                <input
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  type="tel"
                  placeholder="Phone Number"
                  v-model="location.twilio_number"
                  ref="phone"
                  name="location_twilio"
                  @change="validatePhone"
                  autocomplete="off"
                  required
                  id="location_twilio"
                />
                <textarea
                  placeholder="Review Text"
                  class="w-full mt-2 max-h-sm border-gray-200 border-2 py-1 px-3"
                  rows="6"
                  v-model="location.review_text"
                >
                </textarea>
              </div>
            </div>
            <div role="alert" class="my-3" v-if="errorMsg != ''">
              <div
                class="border border-red-400 rounded bg-red-100 text-sm px-4 py-3 text-red-700"
              >
                <p>{{ errorMsg }}.</p>
              </div>
            </div>
            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700 md:w-40 md:mr-3 text-center"
                type="submit"
                :disabled="isSubmitted || errorMsg !== ''"
                :class="
                  isSubmitted || errorMsg !== ''
                    ? 'opacity-75 cursor-not-allowed'
                    : ''
                "
              >
                {{ isSubmitted ? "Saving Location..." : "Save Location" }}
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-40 md:ml-3 md:mt-0"
                @click="showPopup = false"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Please Wait..." : "Cancel" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- edit business popup -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showEditPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div
          class="bg-white rounded shadow pb-8 m-4 max-h-full text-center w-96"
        >
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Edit Location Information
          </div>
          <form @submit.prevent="saveLocation" class="px-4">
            <div class="items-center flex flex-col mt-7">
              <div class="flex flex-col mb-6 w-full mt-2">
                <input
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  type="text"
                  placeholder="Name"
                  v-model="location.location_name"
                  name="location_name"
                  autocomplete="off"
                  required
                  id="location_name"
                />
                <input
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  type="tel"
                  placeholder="Phone Number"
                  v-model="location.twilio_number"
                  name="location_twilio"
                  autocomplete="off"
                  @change="validatePhone"
                  ref="phone"
                  required
                  id="location_twilio"
                />
                <textarea
                  placeholder="Review Text"
                  class="w-full mt-2 max-h-sm border-gray-200 border-2 py-2 pl-1"
                  rows="6"
                  v-model="location.review_text"
                >
                </textarea>
              </div>
            </div>
            <div role="alert" class="my-3" v-if="errorMsg != ''">
              <div
                class="border border-red-400 rounded bg-red-100 text-sm px-4 py-3 text-red-700"
              >
                <p>{{ errorMsg }}.</p>
              </div>
            </div>
            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700 md:w-40 md:mr-3 text-center"
                type="submit"
                :disabled="isSubmitted || errorMsg !== ''"
                :class="
                  isSubmitted || errorMsg !== ''
                    ? 'opacity-75 cursor-not-allowed'
                    : ''
                "
              >
                {{ isSubmitted ? "Saving Location..." : "Save Location" }}
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-40 md:ml-3 md:mt-0"
                @click="showEditPopup = false"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Please Wait..." : "Cancel" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- deactivating / activating location confirmation popup -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showConfirmationPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div class="bg-white rounded shadow pb-8 m-4 max-h-full text-center">
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Are you sure to
            {{ location.status === "active" ? "deactivate" : "activate" }}
            <span class="text-blue-400"> {{ location.location_name }} </span>
          </div>
          <form @submit.prevent="changeLocationStatus">
            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700 md:w-24 md:mr-3 text-center"
                type="submit"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                Yes
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-24 md:ml-3 md:mt-0"
                @click="showConfirmationPopup = false"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                No
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessHeader from "./BusinessHeader";
const firebase = require("../firebaseConfig");
import axios from "axios";

export default {
  name: "LocationsComponent",
  components: {
    BusinessHeader,
  },
  data: () => ({
    business: {},
    showPopup: false,
    showEditPopup: false,
    showConfirmationPopup: false,
    locations: [],
    user: {},
    isSubmitted: false,
    phoneNumber: "",
    errorMsg: "",
    location: {
      name: "",
      twilio_number: "",
      review_text: "",
    },
  }),
  watch: {
    $route() {
      this.$route.meta.title = `${this.$route.meta.title} / ${this.business.business_name}`;
    },
  },
  async mounted() {
    this.$route.meta.title = `${this.$route.meta.title} / ${this.business.business_name}`;
    this.business.id = this.$route.params.business_id;

    this.user = await this.getUserInfo();
    this.getLocations();
    this.getUserInfo();
  },
  methods: {
    getLocations() {
      firebase.db
        .collection("businesses")
        .doc(
          this.user.role !== "superadmin"
            ? this.user.business_id
            : this.business.id
        )
        .collection("locations")
        .onSnapshot({ includeMetadataChanges: true }, (docs) => {
          this.locations = [];
          docs.forEach((element) => {
            this.locations.push({
              id: element.id,
              ...element.data(),
            });
          });
        });
    },
    validatePhone() {
      this.errorMsg = "";
      this.location.twilio_number = this.location.twilio_number
        .replace(/[^0-9]/g, "")
        .replace(/^1+/g, "");
      if (this.location.twilio_number.length != 10) {
        this.errorMsg = `Phone number has to be exactly 10 digit`;
        this.$refs.phone.focus();
        return false;
      }
      return true;
    },
    async addLocation() {
      this.isSubmitted = true;

      this.location.twilio_number = this.location.twilio_number
        .replace(/[^0-9]/g, "")
        .replace(/^1/g, "");

      if (this.location.twilio_number.length != 10) {
        this.errorMsg = `Phone number has to be exactly 10 digit`;
        this.isSubmitted = false;
        return false;
      }

      if (await this.validatePhoneNumber()) {
        this.errorMsg = "Phone number already exist";
        this.isSubmitted = false;
        return false;
      }
      await firebase.db
        .collection("businesses")
        .doc(
          this.user.role !== "superadmin"
            ? this.user.business_id
            : this.business.id
        )
        .collection("locations")
        .add({
          location_messages_received: 0,
          location_messages_sent: 0,
          location_name: this.location.name,
          location_mass_text_campaigns: 0,
          ...(this.location.review_text && {
            review_text: this.location.review_text,
          }),
          status: "active",
          twilio_number: this.location.twilio_number,
        });
      this.isSubmitted = false;
      this.showPopup = false;
      this.location = {};
    },
    async saveLocation() {
      this.isSubmitted = true;

      this.location.twilio_number = this.location.twilio_number
        .replace(/[^0-9]/g, "")
        .replace(/^1/g, "");

      if (this.location.twilio_number.length != 10) {
        this.errorMsg = `Phone number has to be exactly 10 digit`;
        this.isSubmitted = false;
        return false;
      }

      if (this.phoneNumber !== this.location.twilio_number) {
        if (await this.validatePhoneNumber()) {
          this.errorMsg = "Phone number already exist";
          this.isSubmitted = false;
          return false;
        }
      }

      await firebase.db
        .collection("businesses")
        .doc(
          this.user.role !== "superadmin"
            ? this.user.business_id
            : this.business.id
        )
        .collection("locations")
        .doc(this.location.id)
        .update({
          location_name: this.location.location_name,
          twilio_number: this.location.twilio_number,
          review_text: this.location.review_text,
        });
      this.isSubmitted = false;
      this.showEditPopup = false;
    },
    editLocation(location) {
      this.phoneNumber = location.twilio_number;
      this.errorMsg = "";
      this.showEditPopup = true;
      this.location = { ...location };
    },
    toggleModal() {
      this.showPopup = true;
      this.errorMsg = "";
      this.location = {};
    },
    toggleConfirmationStatus(location) {
      this.location = { ...location };
      this.showConfirmationPopup = true;
    },
    async changeLocationStatus() {
      await firebase.db
        .collection("businesses")
        .doc(
          this.user.role !== "superadmin"
            ? this.user.business_id
            : this.business.id
        )
        .collection("locations")
        .doc(this.location.id)
        .update({
          status: this.location.status === "active" ? "inactive" : "active",
        });
      this.showConfirmationPopup = false;
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("users")
          .doc(firebase.auth.currentUser.uid)
          .get()
          .then((user) => {
            resolve(user.data());
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    validatePhoneNumber() {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/checkPhone`,
          method: "post",
          data: {
            phone: this.location.twilio_number,
          },
        })
          .then((phone) => {
            resolve(phone.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
</script>
