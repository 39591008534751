<template>
  <LocationsComponent />
</template>

<script>
import LocationsComponent from '../components/LocationsComponent.vue'

export default {
  name: 'Locations',
  components: {
    LocationsComponent,
  },
}
</script>
