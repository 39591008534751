import { render } from "./LocationsComponent.vue?vue&type=template&id=bec6cc22"
import script from "./LocationsComponent.vue?vue&type=script&lang=js"
export * from "./LocationsComponent.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "bec6cc22"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('bec6cc22', script)) {
    api.reload('bec6cc22', script)
  }
  
  module.hot.accept("./LocationsComponent.vue?vue&type=template&id=bec6cc22", () => {
    api.rerender('bec6cc22', render)
  })

}

script.__file = "src/components/LocationsComponent.vue"

export default script